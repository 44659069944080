import { judgeDevice } from "kits";

/**
 * @ description 获取默认配置
 * @returns
 */
function getDefaultConfig() {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : {};
  return {
    user_name: user?.userInfo?.email ?? "",
    is_logged_in: user?.userInfo?.email ? "1" : "0",
    client_type: judgeDevice() === "pc" ? "1" : "0"
  };
}

/**
 * @description 初始化gtag
 * @param {*} gtagId
 */
function initGtag(gtagId) {
  function gtag() {
    window.dataLayer.push(arguments);
  }

  window.dataLayer = window.dataLayer || [];
  if (window) {
    window.gtag = gtag;
    window.__gtag_id__ = gtagId;
  }
  gtag("js", new Date());
  // 初始化配置参数
  const config = getDefaultConfig();
  gtag("config", gtagId, config);
}

/**
 * @description 更新gtag配置
 * @param {*} config
 */
export function updateGtagConfig(config) {
  if (window && window.gtag) {
    const defaultConfig = getDefaultConfig();
    window.gtag("config", window.__gtag_id__, { ...defaultConfig, ...config });
  }
}

export default defineNuxtPlugin(nuxtApp => {
  const { gtagId, gtagTenantIds } = useRuntimeConfig().public;

  initGtag(gtagId);
  // 添加异常处理逻辑
  try {
    if (
      gtagTenantIds.includes(import.meta.env.VITE_TENANT_ID) &&
      import.meta.env.VITE_NODE_ENV === "prod"
    ) {
      useHead({
        script: [
          {
            src: `https://www.googletagmanager.com/gtag/js?id=${gtagId}`,
            async: true,
            // 添加加载失败时的错误处理
            onload: function () {
              console.log("GTM script loaded successfully");
            },
            onerror: function () {
              console.log("Failed to load GTM script");
              // 可以记录错误，或者执行其他操作，比如切换到备用监控方案
            }
          }
        ]
      });
    }
  } catch (error) {
    console.log("Error loading GTM script:", error);
    // 可以记录错误，或者执行其他操作
  }
});
