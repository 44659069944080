import OSS from "ali-oss";
import html2canvas from "html2canvas";
import { Decimal } from "decimal.js";
import { systemInteractor } from "~/core";
import {
  judgeDevice,
  amountTotal as sumKits,
  dateTimeFormat,
  countdown as countdownFn,
  addThousandSeparator as addThousandSeparatorFn,
  jumpNewPage as jumpNewPageFn,
  getImageSize,
  getBrowserInfo as getBrowserInfoFn,
  paramObj,
  debounce as debounceFn
} from "kits";
// 倒计时
function countdown(seconds) {
  return countdownFn(seconds);
}
/**
 * 数组对象累加求和
 * @param {arr} 数据源
 * @param {field} 需要累加的字段
 * @param {decimals} 保留位数(可选)
 */
function sum(arr, field, decimals) {
  return sumKits(arr, field, decimals);
}
/**
 * 下载图片
 * @param {el} 下载的元素
 */
async function download(el, name, url) {
  let ele = null;
  if (el) {
    ele = document.querySelector(el);
  } else {
    ele = document.querySelector(".area");
  }

  const imgHeight = ele.offsetHeight; // 获取DOM高度
  const imgWidth = ele.offsetWidth; // 获取DOM宽度

  // 截取海报
  html2canvas(ele, {
    useCORS: true, //允许跨域
    backgroundColor: "#ececec", //画布背景色，设置null为透明
    width: imgWidth, //画布宽
    height: imgHeight, //画布高
    scale: 1, // 处理模糊问题
    dpi: 300, // 处理模糊问题
    crossOrigin: "Anonymous"
  }).then(canvas => {
    // 返回一个 canvas 对象，在dom中渲染 canvas 对象
    // 转化成 dataurL
    let url = canvas.toDataURL("image/png");

    //调用下载方法
    let a = document.createElement("a"); //创建一个a标签
    let event = new MouseEvent("click"); // 创建一个单击事件
    if (name) {
      a.download = `VirtualProof#${name}`;
    } else {
      a.download = "效果图"; // 设置图片名称
    }

    a.href = url; //指定下载文件名称
    a.dispatchEvent(event); // 触发a的单击事件
    // 这里可以获得截取到的base64格式的图片-url,可进行后续操作，比如传入接口等
  });
}

/**
 * 将字符串中的空格替换为破折号。
 * @param {string} str - 输入的字符串。
 * @return {string} - 替换空格为破折号后的字符串。
 */
function replaceSpacesWithDash(str, bol = true) {
  if (!str) {
    return str;
  }
  if (bol) {
    return encodeURIComponent(str.replace(/ /g, "_").replace(/\//g, "_"));
  } else {
    return str.replace(/ /g, "_").replace(/\//g, "_");
  }
}

/**
 * 将字符串中的破折号替换为空格。
 * @param {string} str - 输入的字符串。
 * @return {string} - 替换破折号为空格后的字符串。
 */
function replaceDashWithSpaces(str) {
  if (!str) {
    return str;
  }
  return str.replace(/_/g, " ");
}

/**
 * 函数防抖
 * @param {fn} 函数体
 * @param {delay} 时间戳
 */
function debounce(fn, delay) {
  return debounceFn(fn, delay, false);
}

export async function uploadFile(param, $Message) {
  // 获取密钥
  let [res, err] = await systemInteractor.getSTSTokenApi();
  let ossClient;
  if (!err) {
    ossClient = new OSS({
      bucket: "weiwo-erp",
      region: "oss-accelerate",
      stsToken: res.SecurityToken,
      accessKeyId: res.AccessKeyId,
      accessKeySecret: res.AccessKeySecret,
      secure: true
    });

    return ossClient
      .multipartUpload(
        `/erp/product_file/${+new Date()}${_getRandomNum()}.${_getFileSuffix(
          param.file
        )}`,
        param.file
      )
      .then(results => {
        param.showMessage && $Message.success("Upload completed");

        return [results, null];
      })
      .catch(err => {
        ossClient = null;
        return [null, err];
      });
  } else {
    $Message.warning("OSS GET TOKEN ERROR");
    return [null, "OSS GET TOKEN ERROR"];
  }
}

const _getRandomNum = (start = 10000, end = 99999, fixed = 0) => {
  const differ = end - start,
    random = Math.random();

  return (start + differ * random).toFixed(fixed);
};
const _getFileSuffix = file => {
  return file.name.slice(file.name.lastIndexOf(".") + 1);
};

// Function to convert div area to image and upload to server
async function convertAndUploadToServer(el, size, url) {
  let divElement;
  if (typeof el === "string") {
    divElement = document.querySelector(el);
  } else {
    divElement = el;
  }
  // Create a canvas from the div element
  let canvas = await html2canvas(divElement, {
    useCORS: true, //允许跨域
    backgroundColor: "#ececec", //画布背景色，设置null为透明
    width: size || 673, //画布宽
    height: size || 673, //画布高
    scale: 1, // 处理模糊问题
    dpi: 600, // 处理模糊问题
    crossOrigin: "Anonymous"
  });
  const imageDataUrl = canvas.toDataURL("image/png");
  // Create a file from the data URL
  const file = base64ToFile(imageDataUrl, "image.png");
  return file;
}

// 将文字转化成图片
async function convertAndUploadToServerForText(el, height, width) {
  let divElement;
  if (typeof el === "string") {
    divElement = document.querySelector(el);
  } else {
    divElement = el;
  }

  // Create a canvas from the div element
  let canvas = await html2canvas(divElement, {
    useCORS: true, //允许跨域
    backgroundColor: "transparent", //画布背景色，设置null为透明
    width, //画布宽
    height, //画布高
    scale: 1, // 处理模糊问题
    dpi: 300, // 处理模糊问题
    crossOrigin: "Anonymous"
  });
  const imageDataUrl = canvas.toDataURL("image/png");
  // Create a file from the data URL
  const file = base64ToFile(imageDataUrl, "image.png");
  return file;
}

// Helper function to convert data URL to file
// function base64ToFile(base64String, contentType, fileName) {
//   const blob = base64ToBlob(base64String, contentType);
//   return new File([blob], fileName, { type: contentType });
// }

function base64ToFile(base64Data, fileName) {
  const base64Arr = base64Data.split(",");
  const contentType = base64Arr[0].match(/:(.*?);/)[1];
  const byteCharacters = atob(base64Arr[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const file = new File(byteArrays, fileName, { type: contentType });
  return file;
}

/**
 * 数字运算
 * @param {arr} 数字集合
 * @param {type} add:加 sun:减 mul:乘 div:除
 * @param {dight} 保留几位
 * @returns Decimal处理的数值
 */
function countNumber(arr, type, dight = 0) {
  let num = null;

  switch (type) {
    case "add":
      num = 0;
      for (let i = 0; i < arr.length; i++) {
        num = Decimal(arr[i] || 0).add(num);
      }
      break;
    case "sub":
      break;
    case "mul":
      num = 1;
      for (let i = 0; i < arr.length; i++) {
        num = Decimal(arr[i] || 1).mul(num);
      }
      break;
    case "div":
      break;
  }

  return dight > 0 ? num.toDecimalPlaces(dight).toNumber() : num;
}

/**
 * 计算总价，单价是几位小数点总价保留几位
 * @param {price} 单价
 * @param {qty} 数量
 * @returns 总价
 */
function pointDigit(price, qty) {
  if (price === 0) {
    return 0;
  } else {
    const dight = String(price).split(".")[1]?.length || 0;
    return (price * qty).toFixed(dight);
  }
}

/**
 * 产品尺寸单位转化
 * @param {size} 尺寸
 * @param {unit} 单位 默认IN
 * @description FT:英尺 CM:厘米 IN:英寸 FT=>IN(*12) CM=>IN(0.3937)
 */
function sizeUnitChange(size, unit = "IN") {
  if (unit === "FT") {
    return (Number(size) * 12).toFixed(2);
  } else if (unit === "CM") {
    return (Number(size) * 0.3937).toFixed(2);
  } else {
    return size;
  }
}

/**
 * 时间日期转化
 * @param {date} 日期
 * @returns 月/日/年
 */
function timeFormat(date) {
  if (date) {
    return dateTimeFormat("MM/dd/yyyy", new Date(date).getTime());
  } else {
    return "";
  }
}

/**
 * 产品金额千分符
 * @param {} amount
 * @param {fixed} 默认保留两位
 * @returns
 */
function addThousandSeparator(amount, fixed = true) {
  return addThousandSeparatorFn(amount, fixed);
}

/**
 * 判断当前设备
 * @returns
 */
const browserRedirect = () => {
  try {
    if (judgeDevice() === "pc") {
      return "PC";
    } else {
      return "Mobile";
    }
  } catch(err) {

  }
  
};

/**
 * 按钮在移动端和pad端拦截
 */
const promptOnXsOrSm = name => {
  const { $Confirm } = useNuxtApp();
  if (["xs", "sm", "md"].includes(name)) {
    $Confirm("Please access this page on a computer.");
    return false;
  }
  return true;
};

/**
 * 根据分辨率判断设备
 * @returns Boolean
 */
function browserRedirectByPixel(name) {
  if (["xs", "sm"].includes(name)) {
    return false;
  } else {
    return true;
  }
}
/**
 * 创建a标签跳转
 *
 * @param {string} str - 跳转地址。
 * @param {boolen} target - 是否新窗口打开
 */
function jumpNewPage(str, target = true) {
  return jumpNewPageFn(str, target);
}

export function getBrowserInfo() {
  const { name, version } = getBrowserInfoFn();
  return `${name} ${version}`;
}

function getScreenInfo() {
  return {
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width
  };
}

function getQueryParams(url) {
  return paramObj(url, false);
}

function initLoginBc(name = "LOGIN", confirm) {
  if (typeof BroadcastChannel === "undefined") return;
  const BC = new BroadcastChannel(name);
  BC.onmessage = () => {
    confirm({
      title:
        "You have switched to another account. Please refresh to continue.",
      showCancelButton: false
    }).then(result => {
      if (result?.isConfirmed) {
        window.location.reload();
      }
    });
  };
}

// 图标预加载 kits获取图片尺寸大小可以满足预加载
function preloadImages(image) {
  return getImageSize(image);
}

export default defineNuxtPlugin(nuxtApp => {
  const { $Message, $Confirm } = useNuxtApp();
  return {
    provide: {
      Utils: {
        countdown,
        sum,
        download,
        replaceSpacesWithDash,
        replaceDashWithSpaces,
        debounce,
        uploadFile: file => {
          return uploadFile(file, $Message);
        },
        convertAndUploadToServer,
        countNumber,
        pointDigit,
        sizeUnitChange,
        timeFormat,
        addThousandSeparator,
        browserRedirect,
        promptOnXsOrSm,
        browserRedirectByPixel,
        jumpNewPage,
        getBrowserInfo,
        convertAndUploadToServerForText,
        getScreenInfo,
        getQueryParams,
        preloadImages,
        onBroadCastChannelMessage: () => {
          return initLoginBc("LOGIN", $Confirm);
        }
      }
    }
  };
});
