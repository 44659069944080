<template>
  <div class="page-footer">
    <div class="d-flex justify-center w-100 sp-container">
      <!-- PC端和pad端 -->
      <v-row class="d-none d-sm-flex justify-space-between m0 p0">
        <!-- 底部logo+联系方式 -->
        <v-col md="4" sm="12" class="d-flex flex-column">
          <!-- 图标 -->
          <div class="d-flex justify-start align-center">
            <v-img
              :src="$multiPlatform.footerLogo"
              :max-width="$multiPlatform.mall != 'RG' ? 286 : 136"
              height="auto"
              style="margin-right: 50px"
            ></v-img>
            <v-img
              v-if="$multiPlatform.mall != 'SW'"
              :src="$multiPlatform.sageFooterLogo"
              max-width="118"
              max-height="60"
            ></v-img>
          </div>
        </v-col>
        <v-col md="1" class="d-sm-none d-lg-flex"></v-col>
        <!-- 竖栏介绍 -->
        <v-col md="7" sm="12" class="d-flex fs-16 fb-400">
          <v-row class="footer-text">
            <v-col sm="12" class="d-flex justify-end align-center">
              <p class="pointer" @click="goPage(1)">Terms of Use</p>
              <p class="line">|</p>
              <p class="pointer" @click="goPage(2)">Privacy Policy</p>
              <p class="line">|</p>
              <p class="pointer" @click="goPage(3)">Contact Us</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- 移动端 -->
      <v-row class="d-flex d-sm-none justify-center footer-content">
        <!-- 顶部Logo -->
        <v-col cols="12" class="d-flex justify-center">
          <v-img
            :src="$multiPlatform.footerLogo"
            max-width="118"
            height="auto"
            class="mr-5"
          ></v-img>
          <v-img
            v-if="$multiPlatform.mall != 'SW'"
            :src="$multiPlatform.sageFooterLogo"
            max-width="118"
            height="auto"
            class="ml-5"
          ></v-img>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="d-flex flex-column pt-2"
          @click="serviceExpend = !serviceExpend"
        >
          <v-expand-transition>
            <ul v-show="serviceExpend" class="mt-4">
              <li class="mb-4 pointer" @click="goPage(1)">Terms of Use</li>
              <li class="my-4 pointer" @click="goPage(2)">Privacy Policy</li>
              <li class="my-4 pointer" @click="goPage(3)">Contact Us</li>
              <!-- <li class="my-4">Privacy Policy</li> -->
            </ul>
          </v-expand-transition>
        </v-col>
      </v-row>
    </div>
    <!-- 分割线 -->
    <v-divider class="mt-4"></v-divider>
    <div class="d-flex flex-column justify-center align-center img-box">
      <div class="d-flex justify-center footer-icon">
        <v-img
          :src="trustwave"
          width="40"
          class="mr-4"
          @click="goUrl(1)"
        ></v-img>
        <v-img :src="u109" width="50" @click="goUrl(2)"></v-img>
      </div>
      <p class="text-center">
        © {{ usYeaer }} {{ $multiPlatform.companyName }} Inc. All rights
        reserved.
      </p>
      <v-row class="d-block d-md-none pa-10"></v-row>
    </div>
  </div>
</template>

<script setup>
import trustwave from "~/assets/imgs/trustwave.jpg";
import u109 from "~/assets/imgs/u222.jpg";
const { $multiPlatform } = useNuxtApp();
const router = useRouter();
const usYeaer = ref("2024"); //美国年份
const serviceExpend = ref(true);

const goPage = num => {
  const obj = {
    1: () => {
      router.push({
        path: "/terms-and-conditions"
      });
    },
    2: () => {
      router.push({
        path: "/privacy-policy"
      });
    },
    3: () => {
      router.push({
        path: "/my-account/contactus"
      });
    }
  };
  obj[num]();
};
const goUrl = num => {
  let url =
    num === 1
      ? "https://www.geotrust.com/"
      : "https://www.pcisecuritystandards.org/";
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.click();
};
</script>

<style lang="scss" scoped>
.page-footer {
  background-color: rgba(85, 85, 85, 1);
  padding: 32px 30px 0;
  color: rgba(255, 255, 255, 0.8);
}
.custom-panel {
  border: none; /* 去掉边框 */
  background-color: transparent; /* 设置背景色为透明 */
  padding: 0; /* 去掉内边距 */
  box-shadow: none; /* 去掉阴影 */
}

.custom-panel .v-expansion-panel-header {
  padding: 0; /* 去掉标题的内边距 */
  background-color: transparent; /* 设置标题背景色为透明 */
  border: none; /* 去掉标题的边框 */
}

.custom-panel .v-expansion-panel-header .v-expansion-panel-header__icon {
  margin-left: 0; /* 去掉箭头的左边距 */
}
.pointer {
  cursor: pointer;
}
.footer-text {
  font-size: 16px;
  .time-cont {
    line-height: 18px;
    span {
      display: block;
      margin-bottom: 8px;
    }
  }
}
.footer-title {
  line-height: 1;
}
.text-center {
  font-size: 16px;
  line-height: 1;
  padding: 16px 0;
}
.img-box {
  margin-top: 16px;
}
.footer-icon {
  :deep(.v-img) {
    cursor: pointer;
    width: 80px !important;
    height: 24px !important;
    border-radius: 2px;
  }
}
.line {
  font-size: 25px;
  margin: 0 10px;
}
</style>
