export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("page:finish", () => {
    var s = document.createElement("script")
    var h = document.querySelector("head") || document.body
    s.src = "https://acsbapp.com/apps/app/dist/js/app.js"
    s.async = true
    s.onload = function () {
      acsbJS.init({
        statementLink: "",
        footerHtml: "",
        hideMobile: false,
        hideTrigger: false,
        disableBgProcess: false,
        language: "en",
        position: "left",
        leadColor: "#146FF8",
        triggerColor: "#146FF8",
        triggerRadius: "50%",
        triggerPositionX: "left",
        triggerPositionY: "bottom",
        triggerIcon: "people",
        triggerSize: "bottom",
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: "small",
          triggerPositionX: "left",
          triggerPositionY: "bottom",
          triggerOffsetX: 10,
          triggerOffsetY: 320,
          triggerRadius: "50%"
        }
      })
      console.log("accessible loaded successfully.")
    }
    s.onerror = function () {
      console.error("accessible loading failed.")
    }

    h.appendChild(s)
  })
})
